import { useState, useCallback, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import {
  differenceWith, isArray, isEqual, isNumber,
} from 'lodash';
import {
  sortingListByCreatedAt,
  sortingListByUpdatedAt,
} from '../utilities/arrayUtil';
import handleStatusMsg from '../utilities/handleStatusMsg';
import { CommentConstants } from '../constants';

function useInfinityScrollHooks({
  currentObjectWithKeyProperty,
  previousLists,
  resetPreviousLists = () => null,
  getMoreLists,
  keyProperty = 'comments',
  limitList = CommentConstants.limitComment,
  dontSorting,
  forceTriggerUpdateOnDataIndexOrderChange,
}) {
  const [isNeedCheckLoadMore, setCheckLoadMore] = useState(false);
  const [isErrorLoadMore, setStatusErrorLoadMore] = useState(false);
  const [lists, setLists] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const sortingCurrentList = useCallback(
    () => sortingListByCreatedAt(currentObjectWithKeyProperty?.[keyProperty]),
    [currentObjectWithKeyProperty],
  );
  const isListsLengthAtTheLimitOrAbove = useCallback(
    (list) => isNumber(list?.length) && list?.length >= limitList,
    [],
  );
  const checkIfListNeedToLoad = useCallback(() => {
    if (!isNeedCheckLoadMore || isErrorLoadMore) return false;

    return isListsLengthAtTheLimitOrAbove(previousLists);
  }, [previousLists, isErrorLoadMore, isNeedCheckLoadMore]);

  useEffect(() => () => resetPreviousLists(), []);

  useEffect(() => {
    if (currentObjectWithKeyProperty?.[keyProperty] === undefined) return;

    const joinedData = dontSorting
      ? currentObjectWithKeyProperty?.[keyProperty]
      : sortingCurrentList();
    const prevArray = [...lists];
    const updatedItems = differenceWith(joinedData, prevArray, isEqual);
    const isItemRemoved = isArray(prevArray) && isArray(joinedData)
    && prevArray.length > joinedData.length;

    if (updatedItems.length > 0 || isItemRemoved || forceTriggerUpdateOnDataIndexOrderChange) {
      setLists([...joinedData]);
    }
  }, [currentObjectWithKeyProperty]);

  const handleLoadMoreLists = async () => {
    try {
      const result = await getMoreLists();
    } catch (error) {
      const status = handleStatusMsg(error, 'error');

      if (status?.message) {
        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      }

      setStatusErrorLoadMore(true);
    }
  };

  return {
    lists,
    handleLoadMoreLists,
    checkIfListNeedToLoad,
    setCheckLoadMore,
    isListsLengthAtTheLimitOrAbove,
    isNeedCheckLoadMore,
  };
}

function useInfinityScrollNotificationHooks({
  currentObjectWithKeyProperty,
  resetPreviousLists = () => null,
  getMoreLists,
  keyProperty = 'data',
  additionalCounterAnchor = null,
}) {
  const [isNeedCheckLoadMore, setCheckLoadMore] = useState(true);
  const [isErrorLoadMore, setStatusErrorLoadMore] = useState(false);
  const [lists, setLists] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const sortingCurrentList = useCallback(
    () => sortingListByUpdatedAt(currentObjectWithKeyProperty?.[keyProperty]),
    [currentObjectWithKeyProperty],
  );
  const isListNeedToload = useCallback(
    (objList) => {
      const { data, total, isFirstLoad } = objList;
      const currentTotalItem = data?.length;
      let isListHasMoreData = true;
      if (total === 0 && data?.length === 0) {
        isListHasMoreData = false;
        return isListHasMoreData;
      }

      if (isFirstLoad) {
        return isListHasMoreData;
      }

      isListHasMoreData = currentTotalItem >= total;

      if (
        additionalCounterAnchor && isNumber(additionalCounterAnchor)
        && additionalCounterAnchor === total
      ) isListHasMoreData = false;

      return isListHasMoreData;
    },
    [currentObjectWithKeyProperty],
  );
  const checkIfListNeedToLoad = useCallback(() => {
    if (!isNeedCheckLoadMore || isErrorLoadMore) return false;

    return isListNeedToload(currentObjectWithKeyProperty);
  }, [currentObjectWithKeyProperty, isErrorLoadMore, isNeedCheckLoadMore]);

  useEffect(() => () => resetPreviousLists(), []);

  useEffect(() => {
    if (currentObjectWithKeyProperty?.[keyProperty] === undefined) return;

    const joinedData = sortingCurrentList();

    const prevArray = [...lists];
    const updatedItems = differenceWith(joinedData, prevArray, isEqual);
    const isItemRemoved = isArray(prevArray) && isArray(joinedData)
    && prevArray.length > joinedData.length;

    if (updatedItems.length > 0 || isItemRemoved) {
      setLists(currentObjectWithKeyProperty?.[keyProperty]);
    }
  }, [currentObjectWithKeyProperty]);

  const handleLoadMoreLists = async () => {
    try {
      const result = await getMoreLists();
    } catch (error) {
      const status = handleStatusMsg(error, 'error');

      if (status?.message) {
        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      }

      setStatusErrorLoadMore(true);
    }
  };

  return {
    lists,
    handleLoadMoreLists,
    checkIfListNeedToLoad,
    setCheckLoadMore,
    isListNeedToload,
  };
}

export {
  useInfinityScrollHooks,
  useInfinityScrollNotificationHooks,
};
