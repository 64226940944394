import {
  cloneDeep, isArray, isNumber,
} from 'lodash';
import { ApiConstants, NotificationConstants } from '../constants';
import { actionTypes } from '../reducers/reducer';
import apiUtil, { getNextPage } from '../utilities/apiUtil';
import {
  getIndexItemByIDFromList,
  updateListSocket,
  mergeObjectListAndRemoveDuplicate,
} from '../utilities/arrayUtil';
import { ErrorException } from '../utilities/handleError';
import { NOTIF_ACTIVITY_API_URL } from '../constants/ApiConstants';

/*
  Dispatcher
*/

function dispatchCurrentNotif({ data }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_NOTIFICATIONS,
    data,
  });
}

function dispatchResetCurrentNotif({}, dispatch) {
  dispatch({
    type: actionTypes.RESET_CURRENT_NOTIFICATIONS,
  });
}

function dispatchResetAllNotif({}, dispatch) {
  dispatch({
    type: actionTypes.RESET_ALL_NOTIFICATIONS,
  });
}

function dispatchUpdateTotalNotif({ total }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_TOTAL_NOTIFICATIONS,
    total,
  });
}

function dispatchUpdateNotif({ updateNotification }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_CURRENT_NOTIFICATIONS,
    updateNotification,
  });
}

function dispatchPreviousAllNotif({ previousAllNotif }, dispatch) {
  dispatch({
    type: actionTypes.SET_PREVIOUS_ALL_NOTIFICATIONS,
    previousAllNotif,
  });
}

function dispatchPreviousCurrentNotif({ previousCurrentNotif }, dispatch) {
  dispatch({
    type: actionTypes.SET_PREVIOUS_CURRENT_NOTIFICATIONS,
    previousCurrentNotif,
  });
}

function dispatchAllNotif({ data }, dispatch) {
  dispatch({
    type: actionTypes.SET_ALL_NOTIFICATIONS,
    data,
  });
}

function dispatchIsFirstLoadAllNotif({ isFirstLoad }, dispatch) {
  dispatch({
    type: actionTypes.SET_IS_FIRST_LOAD_ALL_NOTIFICATIONS,
    isFirstLoad,
  });
}

function dispatchIsFirstLoadNotif({ isFirstLoad }, dispatch) {
  dispatch({
    type: actionTypes.SET_IS_FIRST_LOAD_NOTIFICATIONS,
    isFirstLoad,
  });
}

function dispatchUpdateTotalAllNotif({ total }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_TOTAL_ALL_NOTIFICATIONS,
    total,
  });
}

function dispatchUpdateTotalAllNotifAll({ totalAll }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_TOTAL_ALL_NOTIFICATIONS_ALL,
    totalAll,
  });
}

function dispatchUpdateTotalAllCurrentNotif({ totalAll }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_TOTAL_ALL_CURRENT_NOTIFICATIONS,
    totalAll,
  });
}

function dispatchUpdateAllNotif({ updateAllNotification }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_ALL_NOTIFICATIONS,
    updateAllNotification,
  });
}

function dispatchCounterNotif({ nonChat, chat }, dispatch) {
  dispatch({
    type: actionTypes.SET_COUNTER_NOTIF,
    nonChat,
    chat,
  });
}

function dispatchCounterTaskNotif({ totalCounter }, dispatch) {
  dispatch({
    type: actionTypes.SET_COUNTER_TASK_NOTIF,
    totalCounter,
  });
}

function dispatchCounterCompaniesNotif({ totalCounter, companies }, dispatch) {
  dispatch({
    type: actionTypes.SET_COUNTER_COMPANIES_NOTIF,
    totalCounter,
    companies,
  });
}

function dispatchUpdateCounterCompaniesNotif({ updateCounterCompaniesNotif }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_COUNTER_COMPANIES_NOTIF,
    updateCounterCompaniesNotif,
  });
}

function dispatchUpdateCounterTeamsNotif({ updateCounterTeamsNotif }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_COUNTER_TEAMS_NOTIF,
    updateCounterTeamsNotif,
  });
}

/*
  SetterDispatcher
*/

function setCurrentNotif({ data }, dispatch) {
  if (!data) return;

  dispatchCurrentNotif({ data }, dispatch);
}

function setTotalNotif({ total }, dispatch) {
  if (!isNumber(total)) return;

  dispatchUpdateTotalNotif({ total }, dispatch);
}

function setPreviousAllNotif({ previousAllNotif }, dispatch) {
  if (!previousAllNotif) return;
  dispatchPreviousAllNotif(
    { previousAllNotif: cloneDeep(previousAllNotif) }, dispatch,
  );
}

function setPreviousCurrentNotif({ previousCurrentNotif }, dispatch) {
  if (!previousCurrentNotif) return;
  dispatchPreviousCurrentNotif(
    { previousCurrentNotif: cloneDeep(previousCurrentNotif) }, dispatch,
  );
}

function setAllNotif({ data }, dispatch) {
  if (!data) return;

  dispatchAllNotif({ data }, dispatch);
}

function setIsFirstLoadAllNotif({ isFirstLoad }, dispatch) {
  dispatchIsFirstLoadAllNotif({ isFirstLoad }, dispatch);
}

function setIsFirstLoadNotif({ isFirstLoad }, dispatch) {
  dispatchIsFirstLoadNotif({ isFirstLoad }, dispatch);
}

function setTotalAllNotif({ total }, dispatch) {
  if (!isNumber(total)) return;

  dispatchUpdateTotalAllNotif({ total }, dispatch);
}

function setTotalAllNotifAll({ totalAll }, dispatch) {
  if (!isNumber(totalAll)) return;
  dispatchUpdateTotalAllNotifAll({ totalAll }, dispatch);
}

function setTotalAllCurrentNotif({ totalAll }, dispatch) {
  if (!isNumber(totalAll)) return;
  dispatchUpdateTotalAllCurrentNotif({ totalAll }, dispatch);
}

function setCounterNotif({ chat, nonChat }, dispatch) {
  if (!isNumber(chat) || !isNumber(nonChat)) return;

  dispatchCounterNotif(
    { chat, nonChat },
    dispatch,
  );
}

function setCounterTaskNotif({ totalCounter }, dispatch) {
  if (!isNumber(totalCounter)) return;

  dispatchCounterTaskNotif(
    { totalCounter },
    dispatch,
  );
}

function setCounterCompaniesNotif({ totalCounter, companies }, dispatch) {
  if (!isNumber(totalCounter) || !isArray(companies)) return;

  dispatchCounterCompaniesNotif(
    { totalCounter, companies },
    dispatch,
  );
}

/*
  Helpers
*/

// function getNextPage({ currentNotifications }) {
//   const {
//     data,
//   } = currentNotifications;
//   const { limitNotif } = NotificationConstants;
//   const totalCurrentData = data?.length;
//   const actualPageByCurrentData = Math.ceil(totalCurrentData / limitNotif);
//   const deficitData = (actualPageByCurrentData * limitNotif) - totalCurrentData;

//   if (deficitData === 0) {
//     return actualPageByCurrentData + 1;
//   }

//   return actualPageByCurrentData;
// }

function getListNotificationByType(type) {
  switch (type) {
    // Post
    case 'post':
      return NotificationConstants.typeNotifications.POST;
    case 'postMention':
      return NotificationConstants.typeNotifications.POST;
    case 'postSubscription':
      return NotificationConstants.typeNotifications.POST;
    case 'postSystem':
      return NotificationConstants.typeNotifications.POST;

    // Post comment
    case 'comment':
      return NotificationConstants.typeNotifications.POST_COMMENT;
    case 'postComment':
      return NotificationConstants.typeNotifications.POST_COMMENT;
    case 'postCommentMention':
      return NotificationConstants.typeNotifications.POST_COMMENT;

    // Post discussion
    case 'commentDiscussionPost':
      return NotificationConstants.typeNotifications.POST_DISCUSSION;
    case 'commentDiscussionMentionPost':
      return NotificationConstants.typeNotifications.POST_DISCUSSION;

    // Question
    case 'question':
      return NotificationConstants.typeNotifications.QUESTION;
    case 'questionSystem':
      return NotificationConstants.typeNotifications.QUESTION;
    case 'questionSubscription':
      return NotificationConstants.typeNotifications.QUESTION;

    // Question comment
    case 'questionComment':
      return NotificationConstants.typeNotifications.QUESTION_COMMENT;
    case 'questionCommentMention':
      return NotificationConstants.typeNotifications.QUESTION_COMMENT;

    // Question discussion
    case 'commentDiscussionQuestion':
      return NotificationConstants.typeNotifications.QUESTION_DISCUSSION;
    case 'commentDiscussionMentionQuestion':
      return NotificationConstants.typeNotifications.QUESTION_DISCUSSION;

    // Doc
    case 'doc':
      return NotificationConstants.typeNotifications.DOC;
    case 'docMention':
      return NotificationConstants.typeNotifications.DOC;
    case 'docSubscription':
      return NotificationConstants.typeNotifications.DOC;

    // Doc comment
    case 'docComment':
      return NotificationConstants.typeNotifications.DOC_COMMENT;
    case 'docCommentMention':
      return NotificationConstants.typeNotifications.DOC_COMMENT;

    // Doc discussion
    case 'commentDiscussionDoc':
      return NotificationConstants.typeNotifications.DOC_DISCUSSION;
    case 'commentDiscussionMentionDoc':
      return NotificationConstants.typeNotifications.DOC_DISCUSSION;

    // File
    case 'file':
      return NotificationConstants.typeNotifications.FILE;
    case 'fileSubscription':
      return NotificationConstants.typeNotifications.FILE;

    // File comment
    case 'fileComment':
      return NotificationConstants.typeNotifications.FILE_COMMENT;
    case 'fileCommentMention':
      return NotificationConstants.typeNotifications.FILE_COMMENT;

    // File discussion
    case 'commentDiscussionFile':
      return NotificationConstants.typeNotifications.FILE_DISCUSSION;
    case 'commentDiscussionMentionFile':
      return NotificationConstants.typeNotifications.FILE_DISCUSSION;

    // Bucket
    case 'bucketSubscription':
      return NotificationConstants.typeNotifications.BUCKET;

    // Event
    case 'event':
      return NotificationConstants.typeNotifications.EVENT;
    case 'eventMention':
      return NotificationConstants.typeNotifications.EVENT;
    case 'eventSystem':
      return NotificationConstants.typeNotifications.EVENT;
    case 'eventSubscription':
      return NotificationConstants.typeNotifications.EVENT;

    // Event comment
    case 'eventComment':
      return NotificationConstants.typeNotifications.EVENT_COMMENT;
    case 'eventCommentMention':
      return NotificationConstants.typeNotifications.EVENT_COMMENT;

    // Event discussion
    case 'commentDiscussionEvent':
      return NotificationConstants.typeNotifications.EVENT_DISCUSSION;
    case 'commentDiscussionMentionEvent':
      return NotificationConstants.typeNotifications.EVENT_DISCUSSION;

    // Recurring Occurrence
    case 'recurringOccurrence':
      return NotificationConstants.typeNotifications.RECURRING_OCCURRENCE;
    case 'recurringOccurrenceMention':
      return NotificationConstants.typeNotifications.RECURRING_OCCURRENCE;
    case 'recurringOccurrenceSystem':
      return NotificationConstants.typeNotifications.RECURRING_OCCURRENCE;
    case 'recurringOccurrenceSubscription':
      return NotificationConstants.typeNotifications.RECURRING_OCCURRENCE;

    // Recurring Occurrence comment
    case 'recurringOccurrenceComment':
      return NotificationConstants.typeNotifications.RECURRING_OCCURRENCE_COMMENT;
    case 'recurringOccurrenceCommentMention':
      return NotificationConstants.typeNotifications.RECURRING_OCCURRENCE_COMMENT;

    // Recurring Occurrence discussion
    case 'commentDiscussionRecurringOccurrence':
      return NotificationConstants.typeNotifications.RECURRING_OCCURRENCE_DISCUSSION;
    case 'commentDiscussionMentionRecurringOccurrence':
      return NotificationConstants.typeNotifications.RECURRING_OCCURRENCE_DISCUSSION;

    // Card
    case 'card':
      return NotificationConstants.typeNotifications.CARD;
    case 'cardMember':
      return NotificationConstants.typeNotifications.CARD;
    case 'cardMoved':
      return NotificationConstants.typeNotifications.CARD;
    case 'cardArchived':
      return NotificationConstants.typeNotifications.CARD;
    case 'cardMention':
      return NotificationConstants.typeNotifications.CARD;
    case 'cardSystem':
      return NotificationConstants.typeNotifications.CARD;

    // Card comment
    case 'cardComment':
      return NotificationConstants.typeNotifications.CARD_COMMENT;
    case 'cardCommentMention':
      return NotificationConstants.typeNotifications.CARD_COMMENT;

    // Card discussion
    case 'commentDiscussionCard':
      return NotificationConstants.typeNotifications.CARD_DISCUSSION;
    case 'commentDiscussionMentionCard':
      return NotificationConstants.typeNotifications.CARD_DISCUSSION;

    /// Chat
    case 'chat':
      return NotificationConstants.typeNotifications.CHAT;

    /// Chat
    case 'groupChatMention':
      return NotificationConstants.typeNotifications.GROUP_CHAT;

    // Companies Subscription
    case 'subscriptionSystem':
      return NotificationConstants.typeNotifications.SUBSCRIPTION_SYSTEM;

    case 'cheersSystem':
      return NotificationConstants.typeNotifications.CHEER;

    case 'taskSystem':
      return NotificationConstants.typeNotifications.TASK;

    default:
      return [];
  }
}

/*
  Method
*/

const markReadSingleNotification = async ({
  notificationId,
  companyId,
}) => {
  try {
    if (!notificationId || notificationId === 'undefined') return;
    await apiUtil.patch(
      ApiConstants.URL_V1.NOTIFICATION({ notificationId }),
      {},
      {
        params: {
          companyId,
        },
      },
      NOTIF_ACTIVITY_API_URL,
    );
  } catch (error) {
    throw new ErrorException(error);
  }
};

const markReadMultipleNotifications = async ({
  notificationIds,
  companyId,
}) => {
  try {
    const body = {
      notificationIds,
      companyId,
    };
    await apiUtil.patch(
      ApiConstants.URL_V1.NOTIFICATIONS(),
      body,
      {
        params: {
          selectBy: 'selected',
          companyId,
        },
      },
      NOTIF_ACTIVITY_API_URL,
    );
  } catch (error) {
    throw new ErrorException(error);
  }
};

const markReadGroupChatNotifications = async ({ groupChatId, companyId, notifications }) => {
  if (!groupChatId || !companyId || !notifications) return;
  const notificationGroupChatIds = notifications.filter(
    (elem) => elem?.service?.serviceId === groupChatId,
  )
    .map((elem) => elem._id);

  if (notificationGroupChatIds && notificationGroupChatIds.length > 0) {
    await markReadMultipleNotifications({ notificationIds: notificationGroupChatIds, companyId });
  }
};

function incomingNotification({
  notifications, typeNotif,
}, dispatch) {
  if (!notifications) return;

  const updateNotification = (currentNotifications) => {
    let total = Math.ceil(
      notifications.length / NotificationConstants.limitNotif,
    ) * NotificationConstants.limitNotif;
    let isFirstLoad = currentNotifications?.isFirstLoad;
    const data = notifications;
    let newCurrentNotifications = {};
    if (
      (currentNotifications?.data?.length === notifications?.length)
      && (currentNotifications?.data?.length !== 10 && notifications?.length !== 10)
    ) {
      total += NotificationConstants.limitNotif;
      // data = currentNotifications?.data;
    }
    if (isFirstLoad) isFirstLoad = false;

    newCurrentNotifications = {
      total,
      data,
      isFirstLoad,
    };

    newCurrentNotifications = cloneDeep(newCurrentNotifications);

    return newCurrentNotifications;
  };

  switch (typeNotif) {
    case 'popUp':
      dispatchUpdateNotif({ updateNotification }, dispatch);
      break;
    case 'allNotif':
      dispatchUpdateAllNotif({ updateAllNotification: updateNotification }, dispatch);
      break;
    default:
      break;
  }
}

const resetFirstLoadStatus = ({ typeNotif }, dispatch) => {
  const updateNotification = (currentNotifications) => {
    const { total, data } = currentNotifications;
    let newCurrentNotifications = {
      total,
      data,
      isFirstLoad: true,
    };

    newCurrentNotifications = cloneDeep(newCurrentNotifications);
    return newCurrentNotifications;
  };

  switch (typeNotif) {
    case 'popUp':
      dispatchUpdateNotif({ updateNotification }, dispatch);
      break;
    case 'allNotif':
      dispatchUpdateAllNotif({ updateAllNotification: updateNotification }, dispatch);
      break;
    default:
      break;
  }
};

const incomingCounterCompanies = (
  {
    totalCounter, companies, companyId,
  }, dispatch,
) => {
  const updateCounterCompaniesNotif = (counterCompaniesNotif) => {
    const currentCompany = companies.find((value) => value?.company?._id === companyId);
    if (!currentCompany) {
      return {
        totalCounter,
        companies,
      };
    }
    const totalCounterCompany = currentCompany.unreadNotification + currentCompany.unreadChat;
    const finalTotalCounter = totalCounter - totalCounterCompany;
    const finalCompanies = companies.filter((value) => value?.company?._id !== companyId);

    const newCounterCompanies = {
      totalCounter: finalTotalCounter,
      companies: finalCompanies,
    };
    return cloneDeep(newCounterCompanies);
  };

  dispatchUpdateCounterCompaniesNotif({ updateCounterCompaniesNotif }, dispatch);
};

const incomingCounterTeams = (
  {
    teams,
  }, dispatch,
) => {
  const updateCounterTeamsNotif = (counterTeamsNotif) => cloneDeep(teams);

  dispatchUpdateCounterTeamsNotif({ updateCounterTeamsNotif }, dispatch);
};

const incomingCounterTeam = (
  {
    unreadNotification,
    teamId,
  }, dispatch,
) => {
  if (!teamId || !isNumber(unreadNotification)) return;
  const updateCounterTeamsNotif = (counterTeamsNotif) => {
    const counterTeams = cloneDeep(counterTeamsNotif);
    let counterTeamValue = counterTeams.find((value) => value.team._id === teamId);

    if (counterTeamValue?.unreadNotification === unreadNotification) return counterTeams;

    if (!counterTeamValue) {
      const newData = {
        team: {
          _id: teamId,
        },
        unreadNotification,
      };
      const newCounterTeams = updateListSocket({
        newData,
        currentList: counterTeams,
      });

      return cloneDeep(newCounterTeams);
    }
    counterTeamValue = {
      ...counterTeamValue,
      unreadNotification,
    };

    const indexData = getIndexItemByIDFromList(counterTeams, counterTeamValue.team, 'team._id');
    if (indexData >= 0) counterTeams[indexData] = counterTeamValue;

    return cloneDeep(counterTeams);
  };

  dispatchUpdateCounterTeamsNotif({ updateCounterTeamsNotif }, dispatch);
};

const resetCurrentNotif = ({}, dispatch) => {
  dispatchResetCurrentNotif({}, dispatch);
};

const resetAllNotif = ({}, dispatch) => {
  dispatchResetAllNotif({}, dispatch);
};

async function initiateUnreadNotificationsForGroupChat({
  companyId,
  teamId,
  userId,
}) {
  try {
    const result = await apiUtil.get(
      NOTIF_ACTIVITY_API_URL + ApiConstants.URL_V1.NOTIFICATIONS(), {
        params: {
          limit: NotificationConstants.limitNotif,
          companyId,
          teamId,
          filter: 'unread',
          userId,
        },
      },
    );

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function initiateNotifications({
  page = 1,
  limit = NotificationConstants.limitNotif,
  companyId,
  teamId,
  filter,
  userId,
}, dispatch) {
  try {
    const result = await apiUtil.get(NOTIF_ACTIVITY_API_URL + ApiConstants.URL_V1.NOTIFICATIONS(), {
      params: {
        page,
        limit,
        companyId,
        teamId,
        filter,
        userId,
      },
    });

    const { data, total } = result?.data;

    if (filter === 'all') {
      setAllNotif({ data }, dispatch);
      setTotalAllNotif({ total }, dispatch);
      setTotalAllNotifAll({ totalAll: total }, dispatch);
      if (total < NotificationConstants.limitNotif) {
        setIsFirstLoadAllNotif({ isFirstLoad: false }, dispatch);
      }
    } else if (filter === 'unread') {
      setCurrentNotif({ data }, dispatch);
      setTotalNotif({ total }, dispatch);
      setTotalAllCurrentNotif({ totalAll: total }, dispatch);
      if (total < NotificationConstants.limitNotif) {
        setIsFirstLoadNotif({ isFirstLoad: false }, dispatch);
      }
    }

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function loadMoreNotifications({
  currentNotifications,
  companyId,
  teamId,
  filter,
  userId,
}, dispatch) {
  try {
    const page = getNextPage({
      data: currentNotifications.data,
      limitPerPage: NotificationConstants.limitNotif,
    });

    const result = await apiUtil.get(NOTIF_ACTIVITY_API_URL + ApiConstants.URL_V1.NOTIFICATIONS(), {
      params: {
        page,
        limit: NotificationConstants.limitNotif,
        companyId,
        teamId,
        filter,
        userId,
      },
    });

    const total = page * NotificationConstants.limitNotif;
    const mergeDataNotifications = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentNotifications,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    if (filter === 'all') {
      setAllNotif({ data: mergeDataNotifications?.data }, dispatch);
      setTotalAllNotif({ total }, dispatch);
      setIsFirstLoadAllNotif({ isFirstLoad: false }, dispatch);

      setTotalAllNotifAll({ totalAll: result?.total }, dispatch);
    } else if (filter === 'unread') {
      setCurrentNotif({ data: mergeDataNotifications?.data }, dispatch);
      setTotalNotif({ total }, dispatch);
      setIsFirstLoadNotif({ isFirstLoad: false }, dispatch);
    }
    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

export {
  initiateNotifications,
  initiateUnreadNotificationsForGroupChat,
  loadMoreNotifications,
  setCounterNotif,
  setCurrentNotif,
  setTotalNotif,
  setPreviousAllNotif,
  setPreviousCurrentNotif,
  setAllNotif,
  setTotalAllNotif,
  setTotalAllNotifAll,
  incomingNotification,
  getListNotificationByType,
  resetFirstLoadStatus,
  setCounterCompaniesNotif,
  incomingCounterCompanies,
  incomingCounterTeams,
  incomingCounterTeam,
  resetCurrentNotif,
  resetAllNotif,
  setCounterTaskNotif,
  markReadSingleNotification,
  markReadGroupChatNotifications,
};
