import { memo, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { enqueueSnackbar } from 'notistack';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import { useNotificationChatSocket } from '../../../hooks/NotificationHooks';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import { ChatActions } from '../../../actions';
import { NOTIF_ACTIVITY_API_URL } from '../../../constants/ApiConstants';
import apiUtil from '../../../utilities/apiUtil';
import { ApiConstants } from '../../../constants';

const ChatMobileNotification = ({ companyId, chatId }) => {
  const [{
    user,
    currentChatNotifications,
    currentChatUnreadMessagesCounter,
  }, dispatch] = useContext(GlobalContext);

  useNotificationChatSocket(
    { userId: user?._id, companyId },
    dispatch,
  );

  useEffect(() => {
    if (!currentChatNotifications || !chatId) return;
    const notif = currentChatNotifications.find(
      (notification) => notification.service.serviceId === chatId
        && notification.activities.length > 0,
    );

    if (!notif) return;

    const updateApiNotification = async () => {
      try {
        await apiUtil.patch(
          ApiConstants.URL_V1.NOTIFICATION({ notificationId: notif._id }), {}, {
            params: {
              companyId,
            },
          },
          NOTIF_ACTIVITY_API_URL,
        );
        if (currentChatUnreadMessagesCounter > 0) {
          ChatActions.setCurrentChatUnreadMessagesCounter(0, dispatch);
        }
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      }
    };
    updateApiNotification();
  }, [currentChatNotifications]);

  return null;
};

ChatMobileNotification.propTypes = {
  companyId: PropTypes.string.isRequired,
  chatId: PropTypes.string.isRequired,
};

export default memo(ChatMobileNotification);
