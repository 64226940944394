/* eslint-disable consistent-return */
import { isNumber } from 'lodash';
import {
  useCallback, useContext,
  useEffect, useState,
} from 'react';
import queryString from 'query-string';
import { useLocation, useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { ChatActions, NotificationActions } from '../actions';
import {
  connectSocketNotification, connectSocketNotificationChat,
  connectSocketNotificationCounter,
  connectSocketNotificationCounterCompanies,
  connectSocketNotificationCounterTeams,
  connectSocketNotificationTeam,
  connectSocketNotificationCounterTeam,
} from '../services/socket';
import { useSocketHooks } from './SocketHooks';
import { GlobalContext } from '../contexts/GlobalStateProvider';
import { markReadSingleNotification } from '../actions/NotificationActions';
import handleStatusMsg from '../utilities/handleStatusMsg';

function useNotificationSocket({
  userId, companyId, teamId, typeNotif, filter,
}, dispatch) {
  const callbackNotification = useCallback((notifications) => {
    NotificationActions.incomingNotification({
      notifications,
      typeNotif,
    }, dispatch);
  }, []);

  const resetLoadingNotif = useCallback(() => {
    NotificationActions.resetFirstLoadStatus({
      typeNotif,
    }, dispatch);
  }, []);

  const listenSocket = (socket, params) => {
    const eventParam = teamId ? `${params.teamId}-${params.userId}` : `${params.companyId}-${params.userId}`;
    socket.on(eventParam, callbackNotification);
  };
  const removeListener = (socket, params) => {
    resetLoadingNotif({ typeNotif }, dispatch);
    const eventParam = teamId ? `${params.teamId}-${params.userId}` : `${params.companyId}-${params.userId}`;
    socket.off(eventParam);
    socket.disconnect();
  };

  const { socket } = useSocketHooks({
    params: teamId ? {
      companyId, teamId, userId, filter,
    } : {
      companyId, userId, filter,
    },
    connectSocket: teamId ? connectSocketNotificationTeam : connectSocketNotification,
    listenSocket,
    removeListener,
  });

  return {
    socket,
  };
}

function useNotificationUnreadSocket({
  userId, companyId, teamId, typeNotif, filter,
}, dispatch) {
  const callbackNotification = useCallback((notifications) => {
    NotificationActions.incomingNotification({
      notifications,
      typeNotif,
    }, dispatch);
  }, []);

  const resetLoadingNotif = useCallback(() => {
    NotificationActions.resetFirstLoadStatus({
      typeNotif,
    }, dispatch);
  }, []);

  const listenSocket = (socket, params) => {
    const eventParam = teamId ? `${params.teamId}-${params.userId}-unread` : `${params.companyId}-${params.userId}-unread`;
    socket.on(eventParam, callbackNotification);
  };
  const removeListener = (socket, params) => {
    resetLoadingNotif({ typeNotif }, dispatch);
    const eventParam = teamId ? `${params.teamId}-${params.userId}-unread` : `${params.companyId}-${params.userId}-unread`;
    socket.off(eventParam);
    socket.disconnect();
  };

  const { socket } = useSocketHooks({
    params: teamId ? {
      companyId, teamId, userId, filter,
    } : {
      companyId, userId, filter,
    },
    connectSocket: teamId ? connectSocketNotificationTeam : connectSocketNotification,
    listenSocket,
    removeListener,
  });

  return {
    socket,
  };
}

function useNotificationUnreadForGroupChatSocket({
  userId, companyId, teamId, setGroupChatNotifications,
}) {
  const filter = 'unread';
  const callbackNotificationForGroupChat = useCallback((notifications) => {
    setGroupChatNotifications(notifications);
  }, []);

  const listenSocket = (socket, params) => {
    const eventParam = `${params.teamId}-${params.userId}-unread`;
    socket.on(eventParam, callbackNotificationForGroupChat);
  };
  const removeListener = (socket, params) => {
    const eventParam = `${params.teamId}-${params.userId}-unread`;
    socket.off(eventParam);
    socket.disconnect();
  };

  const { socket } = useSocketHooks({
    params: {
      companyId, teamId, userId, filter,
    },
    connectSocket: connectSocketNotificationTeam,
    listenSocket,
    removeListener,
  });

  return {
    socket,
  };
}

function useNotificationChatSocket({ userId, companyId }, dispatch) {
  const callbackNotification = useCallback((notifications) => {
    ChatActions.incomingNewChatNotif({ notifications }, dispatch);
  }, []);

  const listenSocket = (socket, params) => {
    socket.on(`${params.companyId}-${params.userId}`, callbackNotification);
  };
  const removeListener = (socket, params) => {
    socket.off(`${params.companyId}-${params.userId}`);
    socket.disconnect();
  };

  const { socket } = useSocketHooks({
    params: { companyId, userId },
    connectSocket: connectSocketNotificationChat,
    listenSocket,
    removeListener,
  });

  return {
    socket,
  };
}

function useNotificationCounterSocket({ userId, companyId }, dispatch) {
  const callbackNotification = useCallback((notification) => {
    const totalNonChat = notification?.unreadNotification;
    const totalChat = notification?.unreadChat;
    if (!isNumber(totalNonChat) || !isNumber(totalChat)) return;
    NotificationActions.setCounterNotif({
      nonChat: totalNonChat,
      chat: totalChat,
      isFirstLoad: false,
    }, dispatch);
  }, []);

  const callbackMyTaskNotification = useCallback((notification) => {
    const totalCounter = notification?.unreadTaskNotification;
    if (!isNumber(totalCounter)) return;
    NotificationActions.setCounterTaskNotif({
      totalCounter,
    }, dispatch);
  }, []);

  const listenSocket = (socket, params) => {
    socket
      .on(`${params.companyId}-${params.userId}`, callbackNotification)
      .on(`${params.companyId}-${params.userId}-taskNotif`, callbackMyTaskNotification);
  };
  const removeListener = (socket, params) => {
    socket
      .off(`${params.companyId}-${params.userId}`)
      .off(`${params.companyId}-${params.userId}-taskNotif`);
    socket.disconnect();
  };

  useSocketHooks({
    params: { companyId, userId },
    connectSocket: connectSocketNotificationCounter,
    listenSocket,
    removeListener,
  });
}

function useNotificationCounterCompaniesSocket(
  { userId, companyId }, dispatch,
) {
  const callbackCounterCompanies = useCallback((payload) => {
    const { totalCounter } = payload;
    const companies = payload.companies || payload.listNotifCompanies;
    NotificationActions.incomingCounterCompanies({
      totalCounter,
      companyId,
      companies,
    }, dispatch);
  }, [companyId]);

  const listenSocket = (socket, params) => {
    socket.on(`${params.userId}`, callbackCounterCompanies);
  };
  const removeListener = (socket, params) => {
    socket.off(`${params.userId}`);
    socket.disconnect();
  };

  useSocketHooks({
    params: { userId, companyId },
    connectSocket: connectSocketNotificationCounterCompanies,
    listenSocket,
    removeListener,
  });
}

function useNotificationCounterTeamsSocket(
  { userId, companyId }, dispatch,
) {
  const callbackCounterTeams = useCallback(({ teams }) => {
    NotificationActions.incomingCounterTeams({
      teams,
    }, dispatch);
  }, []);

  const callbackUpdateCounterTeam = useCallback(({ teamId, unreadNotification }) => {
    NotificationActions.incomingCounterTeam({
      teamId,
      unreadNotification,
    }, dispatch);
  }, []);

  const listenSocket = (socket, params) => {
    socket.on(`${params.userId}`, callbackCounterTeams);
    socket.on(`${params.userId}-updateCounter`, callbackUpdateCounterTeam);
  };
  const removeListener = (socket, params) => {
    socket.off(`${params.userId}`);
    socket.off(`${params.userId}-updateCounter`);
    socket.disconnect();
  };

  useSocketHooks({
    params: { userId, companyId },
    connectSocket: connectSocketNotificationCounterTeams,
    listenSocket,
    removeListener,
  });
}

// cannot use this since need looping hooks
function useNotificationCounterTeamSocket(
  { userId, teamId }, dispatch,
) {
  const callbackCounterTeam = useCallback(({ unreadNotification }) => {
    NotificationActions.incomingCounterTeam({
      unreadNotification,
      teamId,
    }, dispatch);
  }, []);

  const listenSocket = (socket, params) => {
    socket.on(`${params.teamId}-${params.userId}`, callbackCounterTeam);
  };
  const removeListener = (socket, params) => {
    socket.off(`${params.teamId}-${params.userId}`);
    socket.disconnect();
  };

  useSocketHooks({
    params: { userId, teamId },
    connectSocket: connectSocketNotificationCounterTeam,
    listenSocket,
    removeListener,
  });
}

const useMarkReadNotificationOnPageOpen = () => {
  const [{ user, counterNotif }] = useContext(GlobalContext);

  const location = useLocation();
  const params = useParams();
  const query = queryString.parse(location.search);

  const [couldStartMarkRead, setCouldStartMarkRead] = useState(false);

  const { readNotifId } = query;

  const { companyId } = params;

  const markReadNotifApi = async (notificationId) => {
    try {
      await markReadSingleNotification({
        notificationId,
        companyId,
      });
    } catch (error) {
      const status = handleStatusMsg(error, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    if (!user?._id) return;
    if (!readNotifId) return;
    if (!companyId) return;
    if (counterNotif.totalNonChat < 1) return;
    if (counterNotif.isFirstLoad) return;

    if (couldStartMarkRead) return;

    // this need to be added to prevent auto read while the page is open
    // later if we can enable this if we want to.
    setCouldStartMarkRead(true);
  }, [counterNotif]);

  useEffect(() => {
    if (!user?._id) return;
    if (!readNotifId) return;
    if (!companyId) return;
    if (counterNotif.totalNonChat < 1) return;
    if (counterNotif.isFirstLoad) return;

    if (!couldStartMarkRead) return;

    markReadNotifApi(readNotifId);
  }, [location, couldStartMarkRead]);
};

export {
  useNotificationSocket,
  useNotificationUnreadSocket,
  useNotificationChatSocket,
  useNotificationCounterSocket,
  useNotificationCounterCompaniesSocket,
  useNotificationCounterTeamsSocket,
  useNotificationCounterTeamSocket,
  useMarkReadNotificationOnPageOpen,
  useNotificationUnreadForGroupChatSocket,
  // useNotificationTeamSocket,
  // useNotificationUnreadTeamSocket,
};
